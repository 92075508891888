import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import swal from "sweetalert";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperGame1 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame2 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 2.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame3 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 3.2,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame4 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    const swiperGame5 = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        },
        slidesPerView: 5.4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        spaceBetween: 10,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    /*const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const handleChangeMT = (e) => {
        setDateMT(e.target.value);
    };
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const handleChangeMN = (e) => {
        setDateMN(e.target.value);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);*/

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        if (profile === null) {
            return navigate("/login");
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "174":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "EG";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [playGame, setPlayGame] = useState(null);
    const { register, handleSubmit } = useForm();
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            let transactionAmount = data.money;
            if (data.money.indexOf(".") != -1) {
                transactionAmount = data.money.replaceAll(".", "");
            }
            if (data.money.indexOf(",") != -1) {
                transactionAmount = data.money.replaceAll(",", "");
            }
            if (Number(data.money) < 0) {
                setLoading(false);
                return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(transactionAmount)
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    const [yoGame, setYoGame] = useState(false);
    const [yoPopup, setYoPopup] = useState(false);
    const [yoType, setYoType] = useState(1);
    const [yoMoney, setYoMoney] = useState(0);
    const [yoBalance, setYoBalance] = useState(0);
    const openYoGame = () => {
        if (profile === null) {
            return navigate("/login");
        }
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/logingame`)
            .then((res) => {
                setYoGame(res.data.data);
                setYoBalance(res.data.data?.AccountInfo?.Balance);
                setYoPopup(true);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
            });
    };
    const [isSubmitting, setIsSubmitting] = useState(false);

    const submitYoGame = async () => {
        if (profile === null) {
            return navigate("/login");
        }
        if (isSubmitting) {
            return swal("Thông báo", "Bạn thao tác quá nhanh", "warning");
        }
        setLoading(true);
        let transactionAmount = yoMoney;
        if (yoMoney.indexOf('.') != -1) {
            transactionAmount = yoMoney.replaceAll('.','')
        }
        if (yoMoney.indexOf(",") != -1) {
            transactionAmount = yoMoney.replaceAll(",", "");
        }
        if (Number(transactionAmount) < 0) {
            setLoading(false);
            return swal("Chú ý", "Vui lòng nhập số tiền cần giao dịch", "warning");
        }
        const formData = {
            amount: Number(transactionAmount),
            type: yoType
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/transfergame`, formData)
            .then((res) => {
                axios
                    .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                    .then((res) => {
                        setProfile(res.data.data);
                        localStorage.setItem("profile", JSON.stringify(res.data.data));
                    })
                    .catch((err) => localStorage.removeItem("user"));
                swal(yoType == 1 ? "Nạp tiền thành công" : "Rút tiền thành công", "", "success");
                setYoBalance(res.data.data.new_balance);
                setTimeout(() => {
                    setIsSubmitting(false);
                }, 3000);
                setLoading(false);
            })
            .catch((err) => {
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                setLoading(false);
                setIsSubmitting(false);
            });
    };
    return (
        <>
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {swiperParams && notify && (
                <div className="bg-black">
                    <div className="box-slide" style={{ overflow: "hidden" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                    </div>
                    {/*<div className="container" style={{ maxWidth: "800px", margin: "30px auto 0" }}>
                        <div className="box-miba">
                            <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                <img alt="" src={require("../../images/xs3mien/mienbac.png")} style={{ maxWidth: "200px" }} />
                            </div>
                            <div className="box-miba-right">
                                <div className="box-text">Miền Bắc</div>
                                <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                <div style={{ display: "flex" }}>
                                    <div className="box-miba-kq" style={{ width: "60%" }}>
                                        {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                            <div className="ball">
                                                <img alt="" src={`/images/ball/${x}.png`} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")} style={{ width: "25%", marginLeft: "15%" }}>
                                        Đặt cược
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Miền Nam
                    </div>
                    <div className="list-game-xs">
                        {dataMN?.map((item, index) => (
                            <>
                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                    <img alt="" src={require(`../../images/xs3mien/${imageMN[index]}.png`)} className="img-xs" />
                                </Link>
                            </>
                        ))}
                    </div>
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Miền Trung
                    </div>
                    <div className="list-game-xs">
                        {dataMT?.map((item, index) => (
                            <>
                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                    <img alt="" src={require(`../../images/xs3mien/${imageMT[index]}.png`)} className="img-xs" />
                                </Link>
                            </>
                        ))}
                    </div>*/}
                </div>
            )}
            <div className="container">
                <div className="section-title2">YoGame</div>
                <Swiper {...swiperGame5}>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_baucua.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_tx.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_txmd5.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_sicbo.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_minpoker.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_baccarat.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_maubinh.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_thantai.png")} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div onClick={openYoGame} className="open-yogame">
                            <img src={require("../../images/dx_tayduky.png")} />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="container">
                <div className="section-title" onClick={() => navigate("/lottery")}>
                    Live Casino
                </div>
                <Swiper {...swiperGame3}>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/dg.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/ag.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/wm.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/s2.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/evolution.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/micro.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "43", game_code: "MG0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="container">
                <div className="section-title" onClick={() => navigate("/sports")}>
                    Thể thao
                </div>
                <Swiper {...swiperGame2}>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/sports_fb_sub.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/sports_sabasports_sub.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../images/games/sports_sub_cmd368.png")} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="container">
                <div className="section-title" onClick={() => navigate("/pvp")}>
                    Game bài
                </div>
                <Swiper {...swiperGame1}>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/v8.png")} style={{ borderRadius: "10px", width: "100%" }} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "102", game_code: "LCC001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="box-game">
                            <div className="box-game-image">
                                <img src={require("../../image/km.png")} style={{ borderRadius: "10px", width: "100%" }} />
                            </div>
                            <div className="box-game-play" onClick={() => launchGame({ product_type: "121", game_code: "KM0001" })}>
                                <span className="box-game-start">Chơi ngay</span>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className="section-title2">Mini Game</div>
                <Swiper {...swiperGame4}>
                    <SwiperSlide>
                        <Link to="/xs75s/lo2">
                            <img alt="" src={require(`../../images/mb75s.png`)} className="img-xs" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to="/xs120s/lo2">
                            <img alt="" src={require(`../../images/st2p.png`)} className="img-xs" />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to="/xd45s">
                            <img alt="" src={require(`../../images/xd45s.png`)} className="img-xs" style={{ transform: "scale(1.2)" }} />
                        </Link>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Link to="/xd60s">
                            <img alt="" src={require(`../../images/xd1p.png`)} className="img-xs" style={{ transform: "scale(1.2)" }} />
                        </Link>
                    </SwiperSlide>
                </Swiper>
                <div style={{ height: "50px" }}></div>
            </div>
            {isShow === true ? (
                <>
                    <div className="popup-backdrop">
                        <div className="popup-main">
                            <div className="popup-header">{title}</div>
                            <div className="popup-content">
                                <div className="gameapi-list">
                                    {game != null &&
                                        game.map((item) => (
                                            <div className="gameapi-item" onClick={() => launchGame({ product_type: item.productType, game_code: item.tcgGameCode })}>
                                                <img src={`https://images.21947392.com/TCG_GAME_ICONS/${item.productCode}/${item.tcgGameCode}.png`} alt={item.gameName} />
                                                <div class="gameapi-name">{item.gameName}</div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <button onClick={() => setShow(false)} className="popup-close">
                                Đóng
                            </button>
                        </div>
                    </div>
                </>
            ) : null}
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main" style={{ position: "relative" }}>
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "20px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "20px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#007aff", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input" style={{ maxWidth: "400px", margin: "0 auto 20px" }}>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        style={{ width: "100%", borderRadius: "20px", fontSize: "15px", padding: "5px 0 5px 15px", color: "#000" }}
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div
                                        className="absolute-input"
                                        onClick={selectAll}
                                        style={{ fontSize: "13px", padding: "10px 20px", height: "90%", top: "5%", right: "2px", borderRadius: "20px" }}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"} style={{ width: "150px", height: "40px", margin: "0 auto" }}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {yoPopup === true && yoGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-close-top" onClick={() => setYoPopup(false)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">YoGame</div>
                        <div className="popup-content">
                            <ul className="tab-navigation tab-game">
                                <li className={yoType === 1 ? "active" : ""} onClick={() => setYoType(1)}>
                                    Nạp tiền
                                </li>
                                <li className={yoType === 2 ? "active" : ""} onClick={() => setYoType(2)}>
                                    Rút tiền
                                </li>
                            </ul>
                            <div className="form-sibo">
                                <input
                                    type="text"
                                    value={yoMoney}
                                    onClick={() => setYoMoney(null)}
                                    onChange={(e) => setYoMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    placeholder="Nhập số tiền"
                                />
                                <button onClick={submitYoGame}>Xác nhận</button>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư YoGame: <b>{yoBalance?.toLocaleString("vi-VN")}</b>
                            </div>
                            <div style={{ textAlign: "center", margin: "10px 0 0" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <button className="open-game" onClick={() => (window.location.href = `https://game.yo68.win?token=${yoGame?.Token}`)}>
                                Vào game
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
            {/*<section className="section-app">
                <div className="container">
                    <div className="d-flexs">
                        <div className="prize-wrap">
                            <div className="prize-box">
                                <div className="prize-title">DANH SÁCH TRÚNG THƯỞNG</div>
                                <div className="prize-header">
                                    <span>Người chơi</span>
                                    <span>Trò chơi</span>
                                    <span>Phần thưởng</span>
                                </div>
                                <div className="prize-board">
                                    <marquee onmouseout="this.start()" onmouseover="this.stop()" direction="up" behavior="scroll" loop="loop" scrollamount="5">
                                        <ul className="br_wiboard_ul">
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                        </ul>
                                    </marquee>
                                </div>
                            </div>
                        </div>
                        <div className="qrcode-wrap" style={{ width: "100%" }}>
                            <div className="qrcode-box">
                                <div className="qrcode_text_one">
                                    <p className="text_scan">Quét Mã</p>
                                    <p>Để tải xuống ứng dụng</p>
                                </div>
                                <div className="down_info">
                                    <div className="down_icon" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <span className={`down_icon_andr active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrAndroid"></use>
                                            </svg>
                                        </span>
                                        <span className={`down_icon_ios active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrIos"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="qrcode" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <img src={require("../../image/qr.jpg")} />
                                        <img src={require("../../image/qr.jpg")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-game">
                <div className="container">
                    <div className="br_bottom_gamelink">
                        <ul>
                            <li className="live" onClick={() => navigate("/casino")}>
                                <i className="icon live"></i>
                                <span className="title">CASINO TRỰC TUYẾN</span>
                                <span className="subTitle">LIVE CASINO</span>
                            </li>
                            <li className="electron" onClick={() => navigate("/slots")}>
                                <i className="icon electron"></i>
                                <span className="title">NỔ HŨ</span>
                                <span className="subTitle">CASINO</span>
                            </li>
                            <li className="sports" onClick={() => navigate("/sports")}>
                                <i className="icon sports"></i>
                                <span className="title">CÁ CƯỢC THỂ THAO</span>
                                <span className="subTitle">SPORT BETTING</span>
                            </li>
                            <li className="lott" onClick={() => navigate("/lottery")}>
                                <i className="icon lott"></i>
                                <span className="title">XỔ SỐ</span>
                                <span className="subTitle">LOTTERY</span>
                            </li>
                        </ul>
                    </div>
                    <div className="br_bottom_ad"></div>
                </div>
            </section>*/}
        </>
    );
}
export default Home;
