import "./footer.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import InfoIcon from "@mui/icons-material/Info";

function Footer() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [popup, setPopup] = useState(false);
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <footer>
                <div className="container">
                    <div className="footer">
                        <div className="Cooperation">
                            <i className="o_icon bb"></i>
                            <i className="o_icon mg"></i>
                            <i className="o_icon pt"></i>
                            <i className="o_icon ag"></i>
                            <i className="o_icon og"></i>
                            <i className="o_icon gd"></i>
                            <i className="o_icon sb"></i>
                        </div>
                        <div className="footer_help">
                            <ul>
                                <li>
                                    <Link>Nội Quy</Link>
                                </li>
                                <li>
                                    <a href={setting?.down_link} target="_blank">
                                        Tải App
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="copyright">Copyright © 2024 All Rights Reserved</div>
                    </div>
                </div>
            </footer>
            <div className="br_right_bar">
                <div className="br_bar_mnav active">
                    <div className="br_sbgimg noticeBoard" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-noticeBoard"></use>
                            </svg>
                        </span>
                        <p>Thông Báo</p>
                    </div>
                    <div className="br_sbgimg liveChat" onClick={() => window.open(`https://t.me/Yo68cskh24h`, "_blank")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-liveChat"></use>
                            </svg>
                        </span>
                        <p>Hỗ trợ trực tuyến</p>
                    </div>
                    <a className="br_sbgimg appDownload" href={setting?.down_link} target="_blank">
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-appDownload"></use>
                            </svg>
                        </span>
                        <p>Tải App</p>
                    </a>
                    <div className="br_sbgimg promotion" onClick={() => navigate("/promotion")}>
                        <span className="br_rightbar_icon">
                            <svg aria-hidden="true" className="svg-icon">
                                <use xlinkHref="#icon-promotion"></use>
                            </svg>
                        </span>
                        <p>Khuyến mãi</p>
                    </div>
                </div>
            </div>
            {/*<div className="btn-info-mini" onClick={() => setPopup(true)}>
                <InfoIcon />
            </div>
            {popup === true && (
                <div className="popup-backdrop">
                    <div className="popup-main">
                        <div className="popup-header">Chú ý</div>
                        <div className="popup-content">
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách rút điểm quy đổi
                                trên 600.000.000 VND. Khi rút điểm cần thực hiện đóng phí duy trì theo hạn mức rút điểm như sau:
                            </p>
                            <ul>
                                <li>Hạn mức rút tài khoản từ 600 triệu - 1.000.000.000 VND tương ứng với 20% phí.</li>
                                <li>Hạn mức rút tài khoản trên 1.000.000.000 VND tương ứng với 30% phí.</li>
                            </ul>
                        </div>
                        <button onClick={() => setPopup(false)} className="popup-close">
                            Đóng
                        </button>
                    </div>
                </div>
            )}*/}
        </>
    );
}
export default Footer;
